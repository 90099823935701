"use client";
import { useSearchParams } from "next/navigation";

export default function useQueryParams<T = any>() {
	const searchParams = useSearchParams();

	const setParams = (name: string, value: any) => {
		const params = new URLSearchParams(searchParams.toString());
		if (Array.isArray(value)) {
			params.delete(name);
			value.forEach((val) => {
				params.append(name, val.toString());
			});
		} else if (value === null || value === "") {
			params.delete(name);
		} else {
			params.set(name, value?.toString());
		}
		return params.toString()
	};

	const getParam = (name: string): string | string[] => {
		return searchParams?.getAll(name);
	};

	const paramsToObject = () => {
		const params: { [key: string]: any } = {};
		searchParams.forEach((value, key) => {
			if (params[key]) {
				params[key] = Array.isArray(params[key])
					? [...params[key], value]
					: [params[key], value];
			} else {
				params[key] = value;
			}
		});
		return params;
	};
	const objectToParams = (obj: any) => {
		const params = new URLSearchParams(searchParams.toString());
		Object.keys(obj).forEach((key) => {
			const value = obj[key]
			if (Array.isArray(value)) {
				params.delete(key);
				value.forEach((val) => {
					params.append(key, val.toString());
				});
			} else if (value == null || value === "") {
				params.delete(key);
			} else {
				params.set(key, value.toString());
			}
		});
		return params.toString();
	}
	return {
		setParams,
		getParam,
		params: searchParams.toString(),
		objectToParams,
		obj: paramsToObject(),
	};
}
